import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { forEach, setWith, isEmpty } from 'lodash'

import { Grid, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  RefreshIcon,
  SaveIcon,
  DownloadIcon,
} from '@enterprise-ui/icons'

import {
  FormTextField,
  NovaTable,
  laceColumnDefsWithUserPreference,
  formatDate,
} from '@dlm/common'

import { convertToLocationZone } from '../../../util/dateUtil'

import { columnDefs, defaultColumnDef } from '../constants/columnDefConstants'
import {
  departureStatusDisplay,
  unknownStatus,
} from '../constants/departureStatusConstants'

const mapDefaultValues = (loads) => {
  const defaultValues = {}
  loads.forEach((load) => {
    defaultValues[`comments-${load.load_id}`] =
      load.departure_log_comments || ''
  })
  return defaultValues
}
const mapLoads = (loads, formContext) => {
  const mappedLoads = []

  loads.forEach((load) => {
    mappedLoads.push({
      departure_log_status: {
        cellValue: load.departure_log_status,
        cellDisplay:
          departureStatusDisplay[load.departure_log_status] ?? unknownStatus,
      },
      origin: {
        cellValue: load.origin.name,
        cellDisplay: (
          <>
            {load.origin.id} - {load.origin.name}
          </>
        ),
      },
      destination: {
        cellValue: load.destination.name,
        cellDisplay: (
          <>
            {load.destination.id} - {load.destination.name}
          </>
        ),
      },
      unload_type: load.delivery_type,
      trailer_number: load.trailer_number,
      cut_time: convertToLocationZone(
        load.cut_time,
        load.origin.time_zone_offset_from_utc,
      ),
      current_scac: load.carrier?.scac,
      pro_number: load.pro_number,
      trip_id: load.trip_id,
      load_id: load.load_id,
      critical_departure_time: convertToLocationZone(
        load.critical_departure,
        load.origin.time_zone_offset_from_utc,
      ),
      arrival_time: load.arrival_time
        ? convertToLocationZone(
            load.arrival_time,
            load.destination.time_zone_offset_from_utc,
          )
        : convertToLocationZone(
            load.expected_delivery_date,
            load.destination.time_zone_offset_from_utc,
          ),
      delivery_sequence: load.delivery_sequence,
      schedule_id: load.schedule_id,
      update_by: load.departure_log_update_by,
      update_date: formatDate(load.update_date),
      comments: {
        cellValue: load.departure_log_comments,
        cellDisplay: (
          <FormTextField
            formContext={formContext}
            name={`comments-${load.load_id}`}
          />
        ),
      },
    })
  })

  return mappedLoads
}

const DepartureLogTable = ({
  loads,
  loadCount,
  userPreferences,
  pageNum,
  pageSize,
  onExport,
  onSave,
  onRefresh,
  onPaginationChange,
  onSortChange,
  onColumnDefsChange,
  ...restProps
}) => {
  const [userColumnDefs, setUserColumnDefs] = useState(columnDefs)

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: mapDefaultValues(loads),
  })

  const {
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    reset,
  } = formContext

  const rowData = useMemo(
    () => mapLoads(loads, formContext),
    [loads, formContext],
  )

  useEffect(() => {
    // reset the form values when loads are updated
    reset(mapDefaultValues(loads))
  }, [loads, reset])

  useEffect(() => {
    setUserColumnDefs(
      laceColumnDefsWithUserPreference(
        columnDefs,
        userPreferences?.config_details?.applications?.departure_log?.tables
          ?.departure_log?.columns,
      ),
    )
  }, [userPreferences])

  const saveDepartureLogUpdates = (values) => {
    const dirtyValues = Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, values[key]]),
    )
    const departureLogUpdates = {}
    forEach(dirtyValues, (value, key) => {
      const [field, loadId] = key.split('-')
      setWith(departureLogUpdates, `${loadId}.${field}`, value, Object)
    })
    onSave(departureLogUpdates)
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <NovaTable
        name="Departure Log"
        showHeader
        enableColumnManager
        columnDefs={userColumnDefs}
        defaultColumnDef={defaultColumnDef}
        rowData={rowData}
        rowCount={loadCount}
        pageNum={pageNum}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
        onSortChange={(direction, field) => {
          const column = userColumnDefs.find((column) => column.field === field)
          onSortChange(direction, column?.sortBy)
        }}
        onColumnDefsChange={onColumnDefsChange}
        tableActions={
          <Grid.Container
            align="center"
            justify="flex-end"
            spacing="dense"
            noWrap
          >
            <Grid.Item>
              <Tooltip content="Save" location="bottom">
                <Button
                  iconOnly
                  aria-label="Save Updates"
                  data-testid="save-btn"
                  className="table-action-button"
                  disabled={!isDirty && isEmpty(errors)}
                  onClick={handleSubmit(saveDepartureLogUpdates)}
                >
                  <EnterpriseIcon size="lg" icon={SaveIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Reset" location="bottom">
                <Button
                  iconOnly
                  aria-label="Reset Loads"
                  data-testid="reset-btn"
                  className="table-action-button"
                  disabled={!isDirty}
                  onClick={() => reset(mapDefaultValues(loads))}
                >
                  <EnterpriseIcon icon={CancelCircleIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Refresh Loads" location="bottom">
                <Button
                  iconOnly
                  aria-label="Refresh Loads"
                  data-testid="refresh-btn"
                  className="table-action-button"
                  onClick={onRefresh}
                >
                  <EnterpriseIcon icon={RefreshIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Export CSV" location="bottom">
                <Button
                  iconOnly
                  aria-label="Export CSV"
                  data-testid="export-csv-btn"
                  className="table-action-button"
                  onClick={() => onExport('DEPARTURE_LOG')}
                >
                  <EnterpriseIcon icon={DownloadIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        }
        {...restProps}
      />
    </form>
  )
}

export default DepartureLogTable
